define('btw-develop/controllers/student', ['exports', 'ember', 'btw-develop/utils/dialogs'], function (exports, _ember, _btwDevelopUtilsDialogs) {
  var computed = _ember['default'].computed;
  var inject = _ember['default'].inject;
  exports['default'] = _ember['default'].Controller.extend({
    emailVerified: computed.reads('session.currentUser.emailVerified'),
    firebaseApp: inject.service(),

    actions: {
      selectProduct: function selectProduct(product) {
        this.transitionToRoute('student.products.product', product);
      },

      refreshPage: function refreshPage() {
        window.location.reload();
      },

      resendEmailVerification: function resendEmailVerification() {
        this.get('firebaseApp').auth().currentUser.sendEmailVerification().then(function () {
          (0, _btwDevelopUtilsDialogs.windowAlert)('verification sent!');
        })['catch'](function (err) {
          (0, _btwDevelopUtilsDialogs.windowAlert)('Verification didn\'t send' + err.message);
        });
      }
    }
  });
});