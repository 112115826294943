define('btw-develop/initializers/stripe', ['exports', 'btw-develop/config/environment'], function (exports, _btwDevelopConfigEnvironment) {
  exports.initialize = initialize;

  function initialize() {
    var application = arguments[1] || arguments[0];
    var _config$stripe = _btwDevelopConfigEnvironment['default'].stripe;
    var stripe = _config$stripe === undefined ? {} : _config$stripe;

    application.register('config:stripe', stripe, { instantiate: false });
    application.inject('service:stripe', 'stripeConfig', 'config:stripe');
  }

  exports['default'] = {
    name: 'stripe',
    initialize: initialize
  };
});