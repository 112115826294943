define('btw-develop/helpers/force-protocol', ['exports', 'ember'], function (exports, _ember) {
  exports.indexPlusOne = indexPlusOne;

  function indexPlusOne(params /*, hash*/) {
    var hasProtocol = new RegExp('http').test(params[0]);
    if (hasProtocol) {
      return params[0];
    } else {
      return 'https://' + params[0];
    }
  }

  exports['default'] = _ember['default'].Helper.helper(indexPlusOne);
});