define('btw-develop/controllers/admin/products', ['exports', 'ember'], function (exports, _ember) {
  var computed = _ember['default'].computed;
  var inject = _ember['default'].inject;
  exports['default'] = _ember['default'].Controller.extend({
    store: inject.service(),
    products: computed.reads('model'),

    showPreview: null,
    showMarkdownHelp: null,

    lessons: computed.filter('products', function (product) {
      return product.get('type') === 'lesson';
    }),
    emptyLessons: computed.equal('lessons.length', 0),

    courses: computed.filter('products', function (product) {
      return product.get('type') === 'course';
    }),
    emptyCourses: computed.equal('courses.length', 0),

    courseOptions: computed(function () {
      return this.get('store').findAll('course');
    }),
    // courseOptions: [
    //   { name: 'California Online Drivers Education' },
    //   { name: 'testCourse' }
    // ],

    dialogOrigin: null,

    creatingProduct: null,
    editingProduct: null,

    newProduct: null,
    currentProduct: null,

    coupons: computed('currentProduct', 'currentProduct.coupons.content', function () {
      return this.get('currentProduct.coupons');
    }),

    actions: {
      newProduct: function newProduct(type) {
        this.set('dialogOrigin', $(event.currentTarget));
        var product = this.store.createRecord('product', {
          name: '',
          price: '0',
          shortDescription: '',
          longDescription: '',
          type: type,
          course: null
        });
        this.set('newProduct', product);
        this.set('creatingProduct', true);
      },

      editProduct: function editProduct(product) {
        this.set('dialogOrigin', $(event.currentTarget));
        this.set('currentProduct', product);
        this.set('editingProduct', true);
      },

      saveProduct: function saveProduct() {
        this.get('currentProduct').save();
        this.set('editingProduct', false);
      },

      cancelEditProduct: function cancelEditProduct() {
        this.set('editingProduct', false);
        this.set('currentProduct', null);
      },

      removeProduct: function removeProduct() {
        this.get('currentProduct').deleteRecord();
        this.get('currentProduct').save();
        this.set('currentProduct', null);
        this.set('editingProduct', false);
      },

      createProduct: function createProduct() {
        this.get('newProduct').save();
        this.set('creatingProduct', false);
        this.set('newProduct', null);
      },

      cancelSaveProduct: function cancelSaveProduct() {
        this.get('newProduct').deleteRecord();
        this.set('creatingProduct', false);
        this.set('newProduct', null);
      },

      togglePreview: function togglePreview() {
        this.toggleProperty('showPreview');
      },

      toggleMarkdownHelp: function toggleMarkdownHelp() {
        this.toggleProperty('showMarkdownHelp');
      },

      newCoupon: function newCoupon() {
        var thisProduct = this.get('currentProduct');
        var store = this.get('store');

        var newCoupon = store.createRecord('coupon', {
          name: 'New Coupon',
          code: 'XXXXX',
          discountPrice: '0',
          product: thisProduct
        });
        thisProduct.get('coupons').addObject(newCoupon);
        //   const newUnit = store.createRecord('unit', {
        //     name: answer,
        //     course: thisCourseId,
        //   });
        //   thisCourse.get('units').addObject(newUnit);
        //   newUnit.save().then(() => {
        //     return thisCourse.save();
        //   });
        // });
      },

      removeCoupon: function removeCoupon(coupon) {
        var _this = this;

        coupon.deleteRecord();
        coupon.save().then(function () {
          return _this.get('currentProduct').save();
        });
      },

      saveCoupon: function saveCoupon(coupon) {
        var _this2 = this;

        coupon.save().then(function () {
          return _this2.get('currentProduct').save();
        });
      }
    }
  });
});
/* global $ */