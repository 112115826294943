define('btw-develop/router', ['exports', 'ember', 'btw-develop/config/environment'], function (exports, _ember, _btwDevelopConfigEnvironment) {

  var Router = _ember['default'].Router.extend({
    location: _btwDevelopConfigEnvironment['default'].locationType,
    rootURL: _btwDevelopConfigEnvironment['default'].rootURL
  });

  Router.map(function () {
    this.route('login');
    this.route('registration');
    this.route('register');

    this.route('student', function () {
      this.route('course', { path: '/course/:id' }, function () {
        this.route('section', { path: '/section/:id' });
        this.route('quiz', { path: '/quiz/:id' });
      });

      this.route('products', function () {
        this.route('product', { path: '/product/:id' });
      });
    });

    this.route('admin', function () {
      this.route('index', { path: '/' });
      this.route('instructors');
      this.route('students');
      this.route('products');
      this.route('schedules');
      this.route('courses', function () {
        this.route('index');
        this.route('course', { path: '/course/:id' });
        this.route('section', { path: '/section/:id' });
        this.route('quiz', { path: '/quiz/:id' });
        this.route('test', { path: '/test/:id' });
      });
    });
  });

  exports['default'] = Router;
});