define('btw-develop/components/display-progress', ['exports', 'ember'], function (exports, _ember) {
  var computed = _ember['default'].computed;
  exports['default'] = _ember['default'].Component.extend({
    // resolved promisearray of student's products
    products: null,

    // resolved promisearray of student's progresses
    progresses: null,

    // the order in question
    order: null,

    product: computed.filter('products', function (product) {
      return product.get('name') === this.get('order.productName');
    })
  });
});