define('btw-develop/controllers/student/course', ['exports', 'ember', 'ember-data', 'btw-develop/utils/dialogs'], function (exports, _ember, _emberData, _btwDevelopUtilsDialogs) {
  var computed = _ember['default'].computed;
  var inject = _ember['default'].inject;
  exports['default'] = _ember['default'].Controller.extend({
    store: inject.service(),
    userInformation: inject.service(),
    navigator: inject.service(),

    progress: null,
    activeUnit: null,
    activeSection: null,
    disableCourseList: false,
    quizAttempt: null,
    activeQuiz: null,

    course: computed.reads('model'),
    student: computed.reads('userInformation.user.content.firstObject'),
    courseTest: computed.reads('model.tests.firstObject'),

    activeQuizIsCourseTest: computed('activeQuiz', 'courseTest', function () {
      return this.get('courseTest') === this.get('activeQuiz');
    }),

    /*
      SectionViews
      - Once the controller's Progress model is set, calculate sectionViews
      - Update this CP when the Progress sectionViews attr gets updated
      - Returns all the student's sectionViews for this course as an array of ID's
    */
    sectionViews: computed('progress', 'progress.sectionViews', function () {
      var progress = this.get('progress');
      if (progress) {
        return _emberData['default'].PromiseObject.create({
          promise: this.get('progress.sectionViews').then(function (sectionViews) {
            var mappedViews = sectionViews.map(function (view) {
              return view.get('sectionId');
            });
            return mappedViews;
          })['catch'](function () {
            return null;
          })
        });
      }
    }),

    /*
      Next:
      - Based on the current section ('activeSection')
      - Stores the 'next' section so that it can be 'clickable'
      - if there is NO ACTIVE SECTION, set the active to first in unit
      - and create a sectionView model
    */
    sectionOnDeck: computed('activeSection', 'progress', function () {
      var active = this.get('activeSection');
      var sections = this.get('activeUnit.sections');

      // find the position of the active inside the sections
      var index = sections.indexOf(active);

      if (sections.get('lastObject') === active) {
        return null;
      }

      return sections.objectAt(index + 1);
    }),

    isLastSection: computed('activeUnit.sections.@each', 'activeSection', function () {
      var lastSection = this.get('activeUnit.sections.lastObject');
      var activeSection = this.get('activeSection');
      return lastSection === activeSection;
    }),

    // Basically, if the unit is passed, then we can assume the quiz is passed.
    activeUnitQuizPassed: computed('activeUnit', 'progress', function () {
      var activeUnit = this.get('activeUnit');
      var passedUnits = this.get('progress.passedUnits');
      var filter = passedUnits.filter(function (unit) {
        return unit === activeUnit;
      });
      return filter.get('length') > 0;
    }),

    unitOnDeck: computed('activeUnit', 'progress', function () {
      var active = this.get('activeUnit');
      return _emberData['default'].PromiseObject.create({
        promise: this.get('model.units').then(function (units) {
          // find the position of the active inside the sections
          var index = units.indexOf(active);

          if (units.get('lastObject') === active) {
            return null;
          }

          return units.objectAt(index + 1);
        })
      });
    }),

    isLastUnit: computed.not('unitOnDeck.content'),

    unitsComplete: computed('progress.passedUnits.@each', function () {
      var courseUnits = this.get('course.units.length');
      var progressUnitsPassed = this.get('progress.passedUnits.length');
      return progressUnitsPassed >= courseUnits;
    }),

    courseSidebarOpen: computed('media', 'navigator', 'media.isMobile', 'media.isDesktop', 'media.isTablet', function () {
      var isSmall = this.get('media.isMobile');
      if (isSmall) {
        //so, uhh.... always show it if the screen id small and we're on the index
        return true;
      } else {
        return false;
      }
    }),

    courseSidebarLockedOpen: computed.not('media.isMobile'),
    actions: {

      nextSection: function nextSection() {
        var next = this.get('sectionOnDeck');
        this.send('updateSection', next);
      },

      updateSection: function updateSection(section) {
        var views = this.get('progress.sectionViews');
        var existing = views.filter(function (view) {
          return view.get('sectionId') === section.get('id');
        });

        // this is no longer preventing extra sectionViews from being spawned
        if (existing.length === 0) {
          this.send('createNewSectionView', section);
        }

        this.set('activeQuiz', null);
        this.set('activeSection', section);
        this.set('disableCourseList', false);

        this.set('courseSidebarOpen', false);

        return window.scrollTo(0, 0);
      },

      createNewSectionView: function createNewSectionView(section) {
        var progress = this.get('progress');
        var newView = this.get('store').createRecord('sectionView', {
          section: section,
          sectionId: section.get('id')
        });
        progress.get('sectionViews').addObject(newView);
        newView.save().then(function () {
          progress.save();
        });
      },

      updateQuiz: function updateQuiz(quiz) {
        var _this = this;

        this.set('activeSection', null);
        this.set('activeQuiz', quiz);
        _ember['default'].run.later(function () {
          _this.set('disableCourseList', true);
          _this.set('courseSidebarOpen', false);
          window.scrollTo(0, 0);
        }, 500);
      },

      // When a student passes a quiz, there is a button to jump to the next unit
      startNextUnit: function startNextUnit() {
        var _this2 = this;

        if (this.get('isLastUnit')) {
          this.send('startCourseTest');
        } else {
          (function () {
            _this2.set('disableCourseList', false);
            _this2.set('activeQuiz', null);

            var active = _this2.get('activeUnit');
            _this2.get('model.units').then(function (units) {
              // find the position of the active inside the sections
              var index = units.indexOf(active);

              if (units.get('lastObject') === active) {
                // should never get here- we should be checking for unitOnDeck and then
                // using a different action for course-finished
                return;
              }

              var nextUnit = units.objectAt(index + 1);
              var firstSectionOfNextUnit = nextUnit.get('sections.firstObject');
              _this2.set('activeUnit', nextUnit);
              return _this2.send('updateSection', firstSectionOfNextUnit);
            });
          })();
        }
      },

      // When a student fails a quiz, there is a button to reset the quiz
      resetUnit: function resetUnit() {
        // return the student to the first section of this unit
        var firstSection = this.get('activeUnit.sections.firstObject');
        this.set('activeQuiz', null);
        this.set('disableCourseList', false);
        return this.set('activeSection', firstSection);
      },

      startCourseTest: function startCourseTest() {
        var courseTest = this.get('model.tests.firstObject');
        this.send('updateQuiz', courseTest);
      },

      quizAlert: function quizAlert() {
        var _this3 = this;

        if (this.get('disableCourseList')) {
          (0, _btwDevelopUtilsDialogs.windowConfirm)('You can\'t navigate the course list while in testing mode. All filled in answers will be reset. Do you want to exit?').then(function (answer) {
            if (answer === true) {
              _this3.set('disableCourseList', false);
              return _this3.send('resetUnit');
            }
          });
        }
      },

      backToList: function backToList() {
        // this.transitionToRoute('student.course');
        if (this.get('activeQuiz')) {
          this.send('quizAlert');
        }
        this.set('courseSidebarOpen', true);
      }
    }
  });
});
/* global window */