define('btw-develop/controllers/admin/courses/quiz', ['exports', 'ember', 'btw-develop/utils/dialogs'], function (exports, _ember, _btwDevelopUtilsDialogs) {
  var inject = _ember['default'].inject;
  exports['default'] = _ember['default'].Controller.extend({
    store: inject.service(),
    editingQuestion: null,
    editingOption: null,

    actions: {
      saveQuiz: function saveQuiz() {
        this.get('model').save();
      },

      /* QUESTIONS */
      addQuestion: function addQuestion() {
        var store = this.get('store');
        var thisQuiz = this.get('model');
        var thisQuizId = thisQuiz.get('id');

        (0, _btwDevelopUtilsDialogs.windowPrompt)('Question:').then(function (answer) {
          var newQuestion = store.createRecord('question', {
            name: answer,
            quizId: thisQuizId
          });
          thisQuiz.get('questions').addObject(newQuestion);
          newQuestion.save().then(function () {
            return thisQuiz.save();
          });
        });
      },

      editQuestion: function editQuestion(question) {
        this.set('editingQuestion', question);
      },

      removeQuestion: function removeQuestion(question) {
        var _this = this;

        (0, _btwDevelopUtilsDialogs.windowConfirm)('You sure?').then(function (answer) {
          if (answer === true) {
            question.deleteRecord();
            question.save().then(function () {
              return _this.get('model').save();
            });
          }
        });
      },

      saveQuestion: function saveQuestion(question) {
        question.save();
        this.set('editingQuestion', null);
      },

      /* OPTIONS */
      addOption: function addOption(question) {
        var store = this.get('store');
        var thisQuestionId = question.get('id');
        (0, _btwDevelopUtilsDialogs.windowPrompt)('Option:').then(function (answer) {
          var newOption = store.createRecord('option', {
            name: answer,
            questionId: thisQuestionId
          });
          question.get('options').addObject(newOption);
          newOption.save().then(function () {
            return question.save();
          });
        });
      },

      editOption: function editOption(option) {
        this.set('editingOption', option);
      },

      removeOption: function removeOption(option, question) {
        (0, _btwDevelopUtilsDialogs.windowConfirm)('You sure?').then(function (answer) {
          if (answer === true) {
            option.deleteRecord();
            option.save().then(function () {
              return question.save();
            });
          }
        });
      },

      saveOption: function saveOption(option) {
        option.save();
        this.set('editingOption', null);
      },

      markCorrect: function markCorrect(option, question) {
        question.get('options').forEach(function (opt) {
          if (opt === option) {
            opt.set('isCorrect', true);
          } else {
            opt.set('isCorrect', false);
          }

          opt.save();
        });
      }
    }
  });
});