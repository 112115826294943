define('btw-develop/routes/student/course', ['exports', 'ember', 'btw-develop/utils/dialogs'], function (exports, _ember, _btwDevelopUtilsDialogs) {
  var inject = _ember['default'].inject;
  var computed = _ember['default'].computed;
  exports['default'] = _ember['default'].Route.extend({
    userInformation: inject.service(),
    navigator: _ember['default'].inject.service(),

    student: computed.reads('userInformation.user.content.firstObject'),

    /*
      SetupController Logic:
      - Get all the Units for this course
      - Set the controller's active unit to the first one
      - A new visit to the student/course route will ALWAYS open the first Units
      - In the future, we want to make this the most recent unit (in progress)
      - Generate a new Progress model tying this student & course together
      - Filter the student's progressModels to find a course match
      - If there is a progress, return it
      - If there is not, create one and set the controller property
    */
    setupController: function setupController(controller, model) {
      var _this = this;

      this._super(controller, model);

      controller.set('progress', null);
      controller.set('activeUnit', null);
      controller.set('activeSection', null);
      controller.set('activeQuiz', null);
      controller.set('quizAttempt', null);
      controller.set('disableCourseList', false);

      var course = model;

      // Check for a progress model and generate one but ONLY if none exist
      var student = this.get('student');

      // get the student's progress models
      student.get('progress').then(function (progressModels) {
        // find the progress model for this course
        var filtered = progressModels.filter(function (progress) {
          var modelCourseId = course.get('id');
          var courseId = progress.get('courseId');
          return modelCourseId === courseId;
        });

        if (filtered.get('length') > 0) {
          controller.set('progress', filtered.get('firstObject'));
        } else {
          (function () {
            var prog = _this.store.createRecord('progress', {
              student: student,
              courseId: course.get('id')
            });
            student.get('progress').addObject(prog);
            prog.save().then(function () {
              student.save();
              controller.set('progress', prog);
            })['catch'](function (error) {
              (0, _btwDevelopUtilsDialogs.windowAlert)(error.message);
            });
          })();
        }

        _ember['default'].run.later(function () {
          course.get('units').then(function (units) {
            var firstUnit = units.get('firstObject');
            controller.set('activeUnit', firstUnit);
            var isSmall = _this.get('media.isMobile');
            if (!isSmall) {
              controller.set('activeSection', firstUnit.get('sections.firstObject'));
            }
          });
        });
      });
    }
  });
});