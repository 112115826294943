define('btw-develop/components/display-section', ['exports', 'ember'], function (exports, _ember) {

  // const {
  //   computed,
  //   inject,
  //   on,
  // } = Ember;

  exports['default'] = _ember['default'].Component.extend({
    section: null,

    actions: {}
  });
});