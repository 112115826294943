define('btw-develop/controllers/login', ['exports', 'ember', 'btw-develop/utils/dialogs'], function (exports, _ember, _btwDevelopUtilsDialogs) {
  exports['default'] = _ember['default'].Controller.extend({
    setting: _ember['default'].inject.service(),
    firebaseApp: _ember['default'].inject.service(),
    email: null,
    password: null,

    showForgotPassword: false,
    forgotPasswordEmail: null,
    showLegacySiteNotification: true,

    actions: {
      login: function login() {
        var _this = this;

        var email = this.get('email');
        var password = this.get('password');

        this.get('session').open('firebase', {
          provider: 'password',
          email: email,
          password: password
        }).then(function (user) {
          return _this.store.query('student', { orderBy: 'uid', equalTo: user.uid }).then(function (student) {
            if (student.content.length) {
              _this.transitionToRoute('student');
            } else {
              _this.transitionToRoute('admin');
            }
          })['catch'](function (error) {
            return error;
          });
        })['catch'](function (error) {
          (0, _btwDevelopUtilsDialogs.windowAlert)(error.message);
        });
      },

      register: function register() {
        this.transitionToRoute('register');
      },

      invalidate: function invalidate() {
        this.get('session').close();
      },

      cancelForgotPassword: function cancelForgotPassword() {
        this.set('showForgotPassword', false);
      },

      sendForgotPasswordEmail: function sendForgotPasswordEmail() {
        var emailAddress = this.get('forgotPasswordEmail');
        var auth = this.get('firebaseApp').auth();
        if (emailAddress) {
          auth.sendPasswordResetEmail(emailAddress).then(function () {
            (0, _btwDevelopUtilsDialogs.windowAlert)('we sent you an email to reset your password');
          })['catch'](function (error) {
            console.log(error);
            if (error.message === "There is no user record corresponding to this identifier. The user may have been deleted.") {
              (0, _btwDevelopUtilsDialogs.windowAlert)('We couldnt find a user in our system registered with that email address');
            } else {
              (0, _btwDevelopUtilsDialogs.windowAlert)(error.message);
            }
            this.set('showForgotPassword', false);
          });
        }
      },

      closeLegacySiteNotification: function closeLegacySiteNotification() {
        this.set('showLegacySiteNotification', false);
      },

      linkToLegacySite: function linkToLegacySite() {
        location.href = 'https://btwds.courseinstruction.com/course/register/6';
      }
    }
  });
});