define('btw-develop/models/setting', ['exports', 'ember-data'], function (exports, _emberData) {
  var attr = _emberData['default'].attr;
  exports['default'] = _emberData['default'].Model.extend({
    siteTitle: attr('string'),
    email: attr('string'),
    phoneNumber: attr('string'),
    streetAddress: attr('string'),
    city: attr('string'),
    state: attr('string'),
    zip: attr('string'),
    licenseNumber: attr('string'),
    termsLink: attr('string'),
    primaryColor: attr('string'),
    secondaryColor: attr('string'),
    accentColor: attr('string'),
    stripeApiKey: attr('string'),
    notifyEmail: attr('string'),
    logoImageUrl: attr('string'),
    signInImageUrl: attr('string'),
    backgroundImageUrl: attr('string')
  });
});