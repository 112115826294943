define('btw-develop/components/course-list-section', ['exports', 'ember'], function (exports, _ember) {
  var computed = _ember['default'].computed;
  var on = _ember['default'].on;
  exports['default'] = _ember['default'].Component.extend({
    classNames: ['btw-list--row', 'btw-course-list--section-title'],
    classNameBindings: ['isFirstSection', 'available:available:unavailable', 'complete', 'active'],

    unit: null,
    section: null,
    index: null,
    sectionViews: null,
    activeSection: null,

    isFirstSection: computed.equal('index', 0),

    // a section is available if it is the first section in the unit OR if it is complete
    available: computed(function () {
      var isFirst = this.get('isFirstSection');
      var isComplete = this.get('complete');
      return isFirst || isComplete;
    }),

    // a section is active if the section matches the activeSection passed down from the list
    active: computed('activeSection', function () {
      var active = this.get('activeSection');
      var section = this.get('section');
      return section === active;
    }),

    // A section is complete if the ID has a matching record in the (completed) progress > sectionViews array passed down from the list
    complete: computed(function () {
      var sectionId = this.get('section.id');
      var sectionViews = this.get('sectionViews.content');
      var filteredViews = sectionViews.filter(function (view) {
        return view === sectionId;
      });
      return filteredViews.length > 0;
    }),

    onClick: on('click', function () {
      if (this.get('isFirst') || this.get('available')) {
        this.sendAction('updateAction', this.get('section'));
      }
    })
  });
});