define('btw-develop/components/markdown-help', ['exports', 'ember'], function (exports, _ember) {

  // const {
  //   computed,
  //   inject,
  //   on,
  // } = Ember;

  exports['default'] = _ember['default'].Component.extend({
    classNames: ['btw-markdown-help'],
    classNameBindings: ['showHelp'],
    showHelp: false,

    closeOnClick: _ember['default'].on('click', function () {
      this.toggleProperty('showHelp');
    }),

    actions: {}
  });
});