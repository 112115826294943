define('btw-develop/controllers/admin/courses/section', ['exports', 'ember', 'btw-develop/utils/dialogs'], function (exports, _ember, _btwDevelopUtilsDialogs) {
  var inject = _ember['default'].inject;
  exports['default'] = _ember['default'].Controller.extend({
    store: inject.service(),
    flashMessages: inject.service(),

    showMarkdownHelp: null,
    showPreview: null,
    actions: {
      saveSection: function saveSection() {
        var _this = this;

        this.get('model').save().then(function () {
          _this.get('flashMessages').success('Saved');
        })['catch'](function () {
          _this.get('flashMessages').error('Couldnt Save');
        });
      },

      removeSection: function removeSection() {
        var _this2 = this;

        (0, _btwDevelopUtilsDialogs.windowConfirm)('This will permanently remove this Section, are you sure you want to remove it?').then(function (answer) {
          if (answer === true) {
            _this2.get('model').deleteRecord();
            _this2.get('model').save();
            _this2.transitionToRoute('admin.courses.course', _this2.get('model.course'));
          }
        });
      },

      togglePreview: function togglePreview() {
        this.toggleProperty('showPreview');
      },

      toggleMarkdownHelp: function toggleMarkdownHelp() {
        this.toggleProperty('showMarkdownHelp');
      },

      back: function back() {
        history.back();
      }
    }
  });
});