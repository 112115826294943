define('btw-develop/services/user-information', ['exports', 'ember', 'ember-data'], function (exports, _ember, _emberData) {
  exports['default'] = _ember['default'].Service.extend({
    session: _ember['default'].inject.service(),
    store: _ember['default'].inject.service(),
    user: _ember['default'].computed('session', 'session.currentUser', function () {
      var _this = this;

      var authId = this.get('session.currentUser.uid');
      if (this.get('session.isAuthenticated')) {
        return _emberData['default'].PromiseObject.create({
          promise: this.get('store').query('student', { orderBy: 'uid', equalTo: authId }).then(function (student) {
            if (student.content.length) {
              return student;
            } else {
              return _this.get('store').query('admin', { orderBy: 'uid', equalTo: authId });
            }
          })['catch'](function () {
            return null;
          })
        });
      } else {
        return null;
      }
    })
  });
});