define('btw-develop/controllers/student/products', ['exports', 'ember'], function (exports, _ember) {
  var computed = _ember['default'].computed;
  var inject = _ember['default'].inject;
  exports['default'] = _ember['default'].Controller.extend({
    product: computed.reads('model'),
    ajax: inject.service(),
    store: inject.service(),
    session: inject.service(),
    navigator: inject.service(),

    userInformation: inject.service(),

    productSidebarOpen: computed('media', 'navigator', 'media.isMobile', 'media.isDesktop', 'media.isTablet', 'navigator.isIndexRoute', function () {
      var isSmall = this.get('media.isMobile');
      var isIndex = this.get('navigator.isIndexRoute');
      if (isSmall && isIndex) {
        //so, uhh.... always show it if the screen id small and we're on the index
        return true;
      } else if (isSmall && !isIndex) {
        // if it's small but NOT the index, we want to CLOSE the sidebar (list)
        return false;
      } else if (!isSmall) {
        return false;
      }
    }),

    productSidebarLockedOpen: computed.not('media.isMobile'),

    student: computed.reads('model.firstObject'),
    products: null,
    studentProducts: computed('products', function () {
      var student = this.get('student');
      return student.get('products');
    }),

    actions: {
      backToList: function backToList() {
        this.transitionToRoute('student.products');
        this.notifyPropertyChange('productSidebarOpen');
      }
    }
  });
});