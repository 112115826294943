define('btw-develop/components/display-quiz', ['exports', 'ember', 'btw-develop/utils/dialogs', 'ember-data'], function (exports, _ember, _btwDevelopUtilsDialogs, _emberData) {
  var computed = _ember['default'].computed;
  var on = _ember['default'].on;
  var inject = _ember['default'].inject;

  /*
    When the user enters the display-quiz component,
    a quizAttempt model has already been created and passed in as 'attempt'
    quizAttempts have answers matching the questions in the quiz
    the answers are never persisted to the server but used to track a right or wrong value
    likewise, the quizAttempt is only saved when the users submits the quiz.
    If the user navigates away from the quiz, the quizAttempt will be destroyed
  */
  exports['default'] = _ember['default'].Component.extend({
    store: inject.service(),
    ajax: inject.service(),
    setting: inject.service(),
    userInformation: inject.service(),
    student: computed.reads('userInformation.user.content.firstObject'),

    // data passed down from controller
    course: null,
    unit: null,
    quiz: null,
    progress: null,
    isLastUnit: null,
    activeQuizIsCourseTest: null,
    attempt: null,

    // component boolean hide/show mechanism for results
    showResults: null,

    coursePassed: computed.reads('progress.coursePassed'),

    // array of filled-in answers
    answers: _ember['default'].A(),

    // blocks quiz submission if there are unanswered quiz questions
    unansweredQuestions: computed('answers.@each.value', function () {
      var answers = this.get('answers');
      var totalAnswered = answers.filter(function (answer) {
        return answer.get('value') !== null;
      });
      return totalAnswered.length < answers.length;
    }),

    // builds out the answers array to be manipulated by the ember-paper radio-group
    setupAnswers: on('init', function () {
      var quiz = this.get('quiz');
      var questions = quiz.get('questions');
      var answers = _ember['default'].A();
      if (!quiz) {
        console.log('quiz was null at init hook');
        return;
      }
      /*
        Loop through the questions in the quiz model,
        and for each one, push an empty answer into the answers array.
        This is so we can avoid using fake answers in the attempt model.
      */
      questions.forEach(function (question) {
        var newAnswer = _ember['default'].Object.create({
          q: question,
          value: null,
          correct: null
        });
        answers.pushObject(newAnswer);
      });
      this.set('answers', answers);
    }),

    wrongAnswers: computed('answers.@each.value', function () {
      var answers = this.get('answers');
      return answers.filterBy('correct', false);
    }),

    bestQuizAttempt: computed('quiz', 'progress.quizAttempts.@each', function () {
      var quiz = this.get('quiz');

      return _emberData['default'].PromiseObject.create({
        promise: this.get('progress.quizAttempts').then(function (attempts) {
          if (!attempts) {
            return null;
          }

          var filteredAttempts = attempts.filter(function (attempt) {
            return attempt.get('quizID') === quiz.get('id');
          });

          if (filteredAttempts.length === 0) {
            return null;
          }

          return filteredAttempts.reduce(function (a, b) {
            if (a.get('score') >= b.get('score')) {
              return a;
            } else {
              return b;
            }
          });
        })
      });
    }),

    hasHighestScore: computed.equal('bestQuizAttempt.score', 100),

    // resets answers when a user navigates away from the quiz- no cheating!
    destroyAnswersOnExit: on('willDestroyElement', function () {
      this.send('resetQuiz');
    }),

    actions: {
      quizPassed: function quizPassed() {
        this.send('resetQuiz');
        this.sendAction('passedQuizAction');
      },

      quizFailed: function quizFailed() {
        this.send('resetQuiz');
        this.sendAction('resetQuizAction');
      },

      resetQuiz: function resetQuiz() {
        this.set('answers', _ember['default'].A());
        this.set('showResults', false);

        // reset fake answer attributes on question models
        var questions = this.get('quiz.questions');
        questions.forEach(function (question) {
          question.set('answer', null);
        });
      },

      setAnswer: function setAnswer(question, option) {
        // Set the answer array's answer- used for scoring the quizAttempt
        var answers = this.get('answers');
        answers.forEach(function (answer) {
          if (answer.get('q') === question) {
            answer.set('value', option.get('name'));
            if (option.get('isCorrect')) {
              answer.set('correct', true);
            } else {
              answer.set('correct', false);
            }
          }
        });

        // Set the question's ephemeral answer attribute- used for showing the current answer
        question.set('answer', option);
      },

      submitQuiz: function submitQuiz() {
        var _this = this;

        (0, _btwDevelopUtilsDialogs.windowConfirm)('Ready to submit your answers?').then(function (answer) {
          if (answer === true) {
            (function () {
              var unit = _this.get('unit');
              var answers = _this.get('answers');
              var progress = _this.get('progress');
              var coursePassed = _this.get('coursePassed');
              var hasHighestScore = _this.get('hasHighestScore');
              var total = answers.length;
              var totalCorrect = answers.filterBy('correct', true).length;
              var score = Math.round(100 * (totalCorrect / total));
              var passed = score > 80;

              var newAttempt = _this.get('store').createRecord('quizAttempt', {
                score: score,
                totalCorrect: totalCorrect,
                progress: progress,
                passed: passed,
                quizID: _this.get('quiz.id')
              });

              _this.set('attempt', newAttempt);

              // if a student PASSES a quiz, add the unit to passedUnits
              if (passed && !coursePassed) {
                progress.get('passedUnits').addObject(unit);
              }

              if (!hasHighestScore || hasHighestScore === false) {
                progress.get('quizAttempts').addObject(newAttempt);

                // save the quizAttempt
                newAttempt.save().then(function () {

                  // if this is the COURSE TEST, set the progress 'coursePassed' boolean value to true
                  // which will activate the god-mode for students in this coursePassed
                  if (_this.get('activeQuizIsCourseTest')) {
                    progress.set('coursePassed', true);
                  }

                  progress.save().then(function () {

                    if (passed) {
                      (0, _btwDevelopUtilsDialogs.windowAlert)('You Passed with a score of ' + score + '%! (' + totalCorrect + '/' + total + ' correct)');
                    } else {
                      (0, _btwDevelopUtilsDialogs.windowAlert)('Oh no, better luck next time! Re-read the section and try again. Your score: ' + score + '% (' + totalCorrect + '/' + total + ' correct)');
                    }
                    // This is the course test; send the 'course passed' email
                    if (_this.get('activeQuizIsCourseTest')) {
                      _this.get('ajax').request('https://us-central1-btwdriving-546dd.cloudfunctions.net/sendCourseCompleteEmail', {
                        method: 'POST',
                        data: {
                          studentEmail: _this.get('student.email'),
                          studentFirstName: _this.get('student.firstName'),
                          product: _this.get('course.name')
                        }
                      }).then(function (message) {
                        console.log(message);
                      })['catch'](function (error) {
                        console.log(error);
                      });
                    }

                    // present the results to the student
                    _this.set('showResults', true);
                  })['catch'](function (error) {
                    console.log(error);
                    return;
                  });
                })['catch'](function (error) {
                  console.log(error);
                  return;
                });
              } else {
                if (passed) {
                  (0, _btwDevelopUtilsDialogs.windowAlert)('You Passed with a score of ' + score + '%! (' + totalCorrect + '/' + total + ' correct)');
                } else {
                  (0, _btwDevelopUtilsDialogs.windowAlert)('Oh no, better luck next time! Re-read the section and try again. Your score: ' + score + '% (' + totalCorrect + '/' + total + ' correct)');
                }
                // present the results to the student
                _this.set('showResults', true);
              }
            })();
          } // if the user replied 'yes' to the prompt
          window.scrollTo(0, 0);
        });
        return;
      }
    }
  });
});