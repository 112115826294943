define('btw-develop/routes/admin/students', ['exports', 'ember'], function (exports, _ember) {

  var PAGE_SIZE = 10;
  var computed = _ember['default'].computed;
  exports['default'] = _ember['default'].Route.extend({
    startAt: null,
    endAt: null,

    model: function model() {
      var _this = this;

      // initally starts at 11
      var query = {
        limitToFirst: PAGE_SIZE + 1
      };

      // if the startAt has already been
      if (this.get('startAt')) {
        query.startAt = this.get('startAt');
      }

      // if the endAt has already been set,
      // remove the limitToFirst
      // and set limitToLast to 11
      if (this.get('endAt')) {
        query.endAt = this.get('endAt');
        delete query.limitToFirst;
        query.limitToLast = PAGE_SIZE + 1;
      }
      // query will initially just be limitToFirst: 11
      return this.store.query('student', query).then(function (students) {
        // but if the startAt value has been set,
        if (_this.get('startAt')) {
          // return all but the first entry of students
          return students.slice(1);
        } else {
          // return everything from the beginning of the students array to end
          return students.slice(0, students.get('length'));
        }
      });
    },

    afterModel: function afterModel(model) {
      var _this2 = this;

      this.store.query('student', { shallow: true }).then(function (list) {
        var len = list.get('length');
        _this2.controller.set('totalStudentRecords', len);

        var firstInModel = model.get('firstObject.id'); // firebase ID
        var lastInModel = model.get('lastObject.id'); // firebase ID

        var mappedList = list.map(function (item) {
          return item.get('id');
        });

        var firstPosition = mappedList.indexOf(firstInModel) + 1;
        var lastPosition = mappedList.indexOf(lastInModel) + 1;

        _this2.controller.set('listStart', firstPosition);
        _this2.controller.set('listEnd', lastPosition);

        // only show the next page if there are more total records than the current endAt
        if (lastPosition < len) {
          _this2.controller.set('showNextButton', true);
        } else {
          _this2.controller.set('showNextButton', false);
        }

        // only show the previous page if the current ID is greater
        // than the Page Size
        if (firstPosition > 1) {
          _this2.controller.set('showPreviousButton', true);
        } else {
          _this2.controller.set('showPreviousButton', false);
        }
      });
    },

    actions: {
      // when the user goes back to the previous 20 entries
      prev: function prev() {
        // get the first entry in our current set (would be 21)
        var id = this.get('currentModel').get('firstObject.id');
        // UNSET the startAt CP
        this.set('startAt', null);
        // SET the endAt to the first one in our current group
        this.set('endAt', id);
        // refresh the model
        this.refresh();
      },

      next: function next() {
        var id = this.get('currentModel').get('lastObject.id');
        this.set('startAt', id);
        this.set('endAt', null);
        this.refresh();
      }
    }
  });
});