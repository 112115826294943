define('btw-develop/controllers/admin/courses/index', ['exports', 'ember'], function (exports, _ember) {
  var computed = _ember['default'].computed;
  var inject = _ember['default'].inject;
  exports['default'] = _ember['default'].Controller.extend({
    store: inject.service(),
    actions: {
      newCourse: function newCourse() {
        var _this = this;

        var newCourse = this.get('store').createRecord('course', {
          name: 'New Course'
        });
        newCourse.save().then(function (course) {
          _this.transitionToRoute('admin.courses.course', course);
        });
      },
      editCourse: function editCourse(course) {
        this.transitionToRoute('admin.courses.course', course);
      }
    }
  });
});