define('btw-develop/routes/student/products/product', ['exports', 'ember'], function (exports, _ember) {
  exports['default'] = _ember['default'].Route.extend({
    model: function model(params) {
      return this.store.findRecord('product', params.id);
    },

    afterModel: function afterModel() {
      window.scrollTo(0, 0);
    }
  });
});