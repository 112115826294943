define('btw-develop/models/student', ['exports', 'ember-data', 'ember'], function (exports, _emberData, _ember) {
  var attr = _emberData['default'].attr;
  var computed = _ember['default'].computed;
  exports['default'] = _emberData['default'].Model.extend({
    uid: attr('string'),
    registeredDate: attr('string'),

    email: attr('string'),
    password: attr('string'),

    firstName: attr('string'),
    lastName: attr('string'),
    middleInitial: attr('string'),

    guardian: attr('string'),
    relationship: attr('string'),

    birthday: attr('string'),
    gender: attr('string'),

    school: attr('string'),
    grade: attr('string'),

    phone: attr('string'),
    guardianPhone: attr('string'),
    homePhone: attr('string'),

    address: attr('string'),
    apt: attr('string'),
    city: attr('string'),
    state: attr('string'),
    zip: attr('string'),

    customerSource: attr('string'),

    driverEdCertNum: attr('string'),
    btwCertNum: attr('string'),
    btwCertDate: attr('string'),
    driverEdCertDate: attr('string'),

    driverLicenseNum: attr('string'),
    driverPermitNum: attr('string'),

    paperFileMade: attr('boolean'),

    comment: attr('string'),

    products: _emberData['default'].hasMany('product'),
    orders: _emberData['default'].hasMany('orders'),
    comments: _emberData['default'].hasMany('comment', { async: true }),
    progress: _emberData['default'].hasMany('progress', { async: true }),

    fullName: computed('firstName', 'lastName', 'middleInitial', function () {
      if (this.get('middleInitial')) {
        return this.get('firstName') + ' ' + this.get('middleInitial') + ' ' + this.get('lastName');
      } else {
        return this.get('firstName') + ' ' + this.get('lastName');
      }
    })
  });
});