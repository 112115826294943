define('btw-develop/models/question', ['exports', 'ember-data'], function (exports, _emberData) {
  var attr = _emberData['default'].attr;
  exports['default'] = _emberData['default'].Model.extend({
    name: attr('string'),
    formId: attr('string'),
    quizId: attr('string'),
    options: _emberData['default'].hasMany('options'),
    correctAnswer: attr('string'), // ID of the correct option?
    answer: attr('string') // ID of the currently selected answer (in student quiz mechanism- local only, never gets saved)
  });
});