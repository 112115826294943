define('btw-develop/components/display-unit', ['exports', 'ember', 'ember-data', 'btw-develop/utils/dialogs'], function (exports, _ember, _emberData, _btwDevelopUtilsDialogs) {
  var computed = _ember['default'].computed;
  exports['default'] = _ember['default'].Component.extend({
    classNames: ['btw-course-list--unit-wrapper'],
    classNameBindings: ['quizPassed.content:unit-passed:unit-not-passed'],

    index: null,
    units: null,
    unit: null,
    progress: null,

    activeSection: null,
    activeUnit: null,
    sectionViews: null,

    isFirstUnit: computed.equal('index', 0),
    /*
      A unit is available if:
      - it is the first unit (it's index will be zero)
      - it's been passed
      - the unit before it has a matching passedUnits record
    */
    unitAvailable: computed('isFirstUnit', 'progress', function () {
      var units = this.get('units');
      var unit = this.get('unit');
      var passedUnits = this.get('progress.passedUnits');
      var previous = units.objectAt(this.get('index') - 1);
      var isAvailable = undefined;
      if (this.get('index') !== 0) {
        isAvailable = passedUnits.filter(function (passedUnit) {
          return passedUnit.get('id') === unit.get('id') || passedUnit.get('id') === previous.get('id');
        });
      }

      return isAvailable || this.get('isFirstUnit');
    }),

    unitSections: computed('unit.sections', function () {
      return this.get('unit.sections.content').toArray().mapBy('id');
    }),

    views: computed.reads('sectionViews.content'),
    intersect: computed.intersect('unitSections', 'views'),

    hasUnreadSections: computed('intersect.length', 'unitSections.length', function () {
      return this.get('intersect.length') < this.get('unitSections.length');
    }),

    quizPassed: computed('unit.quizzes.@each', 'progress.quizAttempts.@each', function () {
      var quiz = this.get('unit.quizzes.firstObject'); // there should only ever be one quiz'
      return _emberData['default'].PromiseObject.create({
        promise: this.get('progress.quizAttempts').then(function (attempts) {
          var filteredAttempts = attempts.filter(function (attempt) {
            return attempt.get('score') > 80 && attempt.get('quizID') === quiz.get('id');
          });
          return filteredAttempts.get('length') > 0;
        })
      });
    }),

    bestQuizAttempt: computed('unit.quizzes.@each', 'progress.quizAttempts.@each', function () {
      var quiz = this.get('unit.quizzes.firstObject'); // there should only ever be one quiz'

      return _emberData['default'].PromiseObject.create({
        promise: this.get('progress.quizAttempts').then(function (attempts) {
          if (!attempts) {
            return null;
          }

          var filteredAttempts = attempts.filter(function (attempt) {
            return attempt.get('quizID') === quiz.get('id');
          });

          return filteredAttempts.reduce(function (a, b) {
            if (a.get('score') >= b.get('score')) {
              return a;
            } else {
              return b;
            }
          });
        })
      });
    }),

    actions: {
      activateUnit: function activateUnit(unit) {
        this.set('activeUnit', unit);
      },

      updateSection: function updateSection(section) {
        this.sendAction('sectionAction', section);
      },

      notifyIncomplete: function notifyIncomplete() {
        (0, _btwDevelopUtilsDialogs.windowAlert)('You cannot click ahead to sections and units until you have completed the section or unit you are currently on');
      },

      updateQuiz: function updateQuiz(quiz) {
        this.sendAction('quizAction', quiz);
      }
    }
  });
});