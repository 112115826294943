define('btw-develop/models/order', ['exports', 'ember-data'], function (exports, _emberData) {
  var attr = _emberData['default'].attr;
  exports['default'] = _emberData['default'].Model.extend({
    amount: attr('string'),
    paidAt: attr('string'),
    inStore: attr('boolean'),
    price: attr('string'),
    stripeId: attr('string'),
    studentId: attr('string'),
    productName: attr('string'),
    productId: attr('string'),
    coupons: _emberData['default'].hasMany('coupons', { async: true })

  });
});
// implicit relationship to student and product