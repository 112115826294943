define('btw-develop/initializers/firebase-flex', ['exports', 'emberfire-utils/initializers/firebase-flex'], function (exports, _emberfireUtilsInitializersFirebaseFlex) {
  Object.defineProperty(exports, 'default', {
    enumerable: true,
    get: function get() {
      return _emberfireUtilsInitializersFirebaseFlex['default'];
    }
  });
  Object.defineProperty(exports, 'initialize', {
    enumerable: true,
    get: function get() {
      return _emberfireUtilsInitializersFirebaseFlex.initialize;
    }
  });
});