define('btw-develop/initializers/vex-theme', ['exports'], function (exports) {
  exports.initialize = initialize;
  /* global window */

  function initialize() /* application */{
    if (window.vex) {
      window.vex.defaultOptions.className = 'vex-theme-os';
    }
  }

  exports['default'] = {
    name: 'vex-theme',
    initialize: initialize
  };
});