define('btw-develop/initializers/navigation', ['exports', 'btw-develop/config/environment'], function (exports, _btwDevelopConfigEnvironment) {
  exports.initialize = initialize;

  function initialize() /* application */{
    var application = arguments[1] || arguments[0];
    var uiNavigator = _btwDevelopConfigEnvironment['default'].uiNavigator;

    uiNavigator = uiNavigator || {};
    var injectionFactories = uiNavigator.injectionFactories || [];
    application.register('config:navigator', uiNavigator, { instantiate: false });
    if (injectionFactories.length > 0) {
      application.inject('service:navigator', 'uiNavigator', 'config:navigator');

      injectionFactories.forEach(function (factory) {
        application.inject(factory, 'navigator', 'service:navigator');
      });
    }
  }

  exports['default'] = {
    name: 'navigator',
    initialize: initialize
  };
});