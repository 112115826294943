define('btw-develop/utils/dialogs', ['exports', 'ember'], function (exports, _ember) {

  _ember['default'].assert('must include the vex dialog library', typeof window.vex !== 'undefined');

  function showVex(vexOpts, method) {
    if (typeof vexOpts === 'string') {
      vexOpts = {
        message: vexOpts
      };
    }

    return new _ember['default'].RSVP.Promise(function (resolve) {
      _ember['default'].merge(vexOpts, {
        callback: resolve
      });

      vex.dialog[method](vexOpts);
    });
  }

  function windowAlert(opts) {
    return showVex(opts, 'alert');
  }

  function windowConfirm(opts) {
    return showVex(opts, 'confirm');
  }

  function windowPrompt(opts) {
    return showVex(opts, 'prompt');
  }

  exports.windowAlert = windowAlert;
  exports.windowConfirm = windowConfirm;
  exports.windowPrompt = windowPrompt;
  exports.alert = windowAlert;
  exports.confirm = windowConfirm;
  exports.prompt = windowPrompt;
});
/* global vex */