define('btw-develop/models/progress', ['exports', 'ember-data'], function (exports, _emberData) {
  // import Ember from 'ember';

  var attr = _emberData['default'].attr;

  /*
  const {
    computed
  } = Ember;
  */

  /*
    # What is a 'Progress' model?
    When a student adds a course-type product
    the app generates an order model, that belongs to the product, to save a record of the payment.
    the app also ties the product record to the student's model
    SO it should also then create a progress model to tie to the student and the course?
    OR should it be created when a student first enters a student/course route? (which would require ?exists checking-logic)
  
    ---
  
    # The Course Model
    A course has many Units
      Units have many Sections and Quizzes
        Quizzes have many questions
          Questions have many options and a single correct answer
  
    ---
  
    # The quizAttempt Model
    A student's progress model can have many quizAttempts
      A quizAttempt has a 'passed' value (boolean)
      A quizAttempt has a totalQuestions attr (number)
      A quizAttempt has a correctQuestions attr (number)
      A quizAttempt has a timestamp attr (string)
  
    ---
  
    # Scenario
    - When a student FIRST enters a course, they will see the course outline on the left
      they land on the course/index route, so NO unit or section will be selected
      but the first Unit will be expanded, and the first section of that unit should be available to select
    - When a student selects a section, a sectionView is created
    - When a student hits the 'next/complete' action button on a section, that sectionView's "completed" value will be true
    - When a student reaches the final section in a Unit, the 'next section' button will be a 'take quiz' button, and the Quiz option will be available
    - When a student starts a quiz, a quizAttempt record will be created
    - When a student answer, their answers will be recorded in the quizAttempt record
    - If a student passes a quiz, the current Unit will be added to the passedUnits RELATIONSHIPS
    - If a unit is in the passedUnits array, it will be marked 'passed'
    - For each unit in the Course List, if the previous unit has been passed, this unit will be 'active'
    - if all units are in the passedUnits array, the TEST button will be active
  */

  exports['default'] = _emberData['default'].Model.extend({
    /* RELATIONSHIPS */
    student: _emberData['default'].belongsTo('student'),
    course: _emberData['default'].belongsTo('course'),

    /* TESTING MECHANISMS */
    courseId: attr('string'),
    currentUnit: attr('string'),
    coursePassed: attr('boolean'),

    passedUnits: _emberData['default'].hasMany('units'),
    sectionViews: _emberData['default'].hasMany('sectionViews'),
    quizAttempts: _emberData['default'].hasMany('quizAttempts')
  });
});