define('btw-develop/controllers/registration', ['exports', 'ember', 'btw-develop/utils/dialogs'], function (exports, _ember, _btwDevelopUtilsDialogs) {
  var RSVP = _ember['default'].RSVP;
  var computed = _ember['default'].computed;
  var inject = _ember['default'].inject;
  exports['default'] = _ember['default'].Controller.extend({
    firebaseApp: inject.service(),
    store: inject.service(),
    session: inject.service(),
    moment: inject.service(),
    ajax: inject.service(),

    firstName: null,
    middleInitial: null,
    lastName: null,

    email: null,
    password: null,
    password2: null,

    address: null,
    apt: null,
    city: null,
    state: null,
    zip: null,

    phone: null,
    guardianPhone: null,
    homePhone: null,

    birthday: null,
    gender: null,

    guardian: null,
    relationship: null,

    school: null,
    grade: null,

    customerSource: null,

    acceptNotifications: false,
    acceptTerms: false,

    hasValidEmail: computed.match('email', /^.+@.+\..+$/),

    passwordIsValid: computed('password', 'password2', function () {
      return this.get('password') === this.get('password2');
    }),

    canHazRegister: computed('email', 'hasValidEmail', 'passwordIsValid', 'address', 'city', 'state', 'birthday', 'acceptTerms', 'acceptNotifications', function () {
      var validEmail = this.get('hasValidEmail') && this.get('email').length > 5;
      var validAddress = this.get('address') !== null;
      var validCity = this.get('city') !== null;
      var validState = this.get('state') !== null;
      var validPass = this.get('passwordIsValid') && this.get('password') !== null;
      var validAgree = this.get('acceptNotifications') && this.get('acceptTerms');

      var testsPassed = validEmail && validAddress && validCity && validState && validPass && validAgree;
      return testsPassed;
    }),

    actions: {
      goToDashboard: function goToDashboard() {
        this.transitionToRoute('student');
      },

      submitRegistration: function submitRegistration() {
        var _this = this;

        var reject = RSVP.reject;
        var auth = this.get('firebaseApp').auth();
        var email = this.get('email');
        var password = this.get('password');

        if (!email || !password) {
          return this.waitFor_(reject(new Error('`email` and `password` must be supplied')));
        }

        // create the user in firebase using createUserWithEmailAndPassword, and then use the resulting data.uid to save it to firebase
        return auth.createUserWithEmailAndPassword(email, password).then(function (result) {
          var newUser = _this.get('store').createRecord('student', {
            uid: result.uid,
            email: result.email,
            firstName: _this.get('firstName'),
            lastName: _this.get('lastName'),
            phone: _this.get('phone'),
            guardianPhone: _this.get('guardianPhone'),
            homePhone: _this.get('homePhone'),
            address: _this.get('address'),
            apt: _this.get('apt'),
            city: _this.get('city'),
            state: _this.get('state'),
            zip: _this.get('zip'),
            birthday: _this.get('birthday'),
            gender: _this.get('gender'),
            school: _this.get('school'),
            grade: _this.get('grade'),
            customerSource: _this.get('customerSource'),
            registeredDate: _this.get('moment').moment()._d
          });

          newUser.save();

          _this.get('session').open('firebase', {
            provider: 'password',
            email: email,
            password: password
          }).then(function () {
            (0, _btwDevelopUtilsDialogs.windowAlert)("You're all signed up! Please verify your email account to get started.");
            _this.send('goToDashboard');
            _this.get('firebaseApp').auth().currentUser.sendEmailVerification().then(function (result) {
              console.log(result);
              var studentEmail = email;
              var studentFirstName = newUser.get('firstName');
              _this.get('ajax').request('https://us-central1-btwdriving-546dd.cloudfunctions.net/sendWelcomeEmail', {
                method: 'POST',
                data: {
                  studentEmail: studentEmail,
                  studentFirstName: studentFirstName
                }
              }).then(function (message) {
                console.log(message);
              })['catch'](function (error) {
                console.log(error);
              });
            })['catch'](function (error) {
              console.log(error);
            });
          })['catch'](function (error) {
            (0, _btwDevelopUtilsDialogs.windowAlert)(error.message);
          });
        })['catch'](function (error) {
          (0, _btwDevelopUtilsDialogs.windowAlert)(error.message);
        });
      }
    }
  });
});