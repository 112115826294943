define('btw-develop/services/setting', ['exports', 'ember', 'ember-data'], function (exports, _ember, _emberData) {
  exports['default'] = _ember['default'].Service.extend({
    store: _ember['default'].inject.service(),
    settings: _ember['default'].computed(function () {
      return _emberData['default'].PromiseObject.create({
        promise: this.get('store').findAll('setting').then(function (setting) {
          return setting.get('firstObject');
        })['catch'](function (error) {
          return null;
        })
      });
    })
  });
});