define('btw-develop/routes/admin/index', ['exports', 'ember'], function (exports, _ember) {
  exports['default'] = _ember['default'].Route.extend({
    model: function model() {
      var _this = this;

      return this.store.findAll('setting').then(function (setting) {
        if (setting.get('firstObject')) {
          return setting.get('firstObject');
        } else {
          var blankSetting = _this.store.createRecord('setting', {
            siteTitle: 'New Web App',
            streetAddress: '000 Xmas Lane',
            city: 'Super City',
            state: 'CA',
            zip: '90000',
            termsLink: 'www.termsandconditions.com',
            primaryColor: '#2196f3',
            secondaryColor: '#ff9800',
            accentColor: '##3f51b5',
            stripeApiKey: '',
            notifyEmail: 'example@example.com'
          });
          return blankSetting.save();
        }
      });
    }
  });
});