define('btw-develop/models/product', ['exports', 'ember-data'], function (exports, _emberData) {
  var attr = _emberData['default'].attr;
  exports['default'] = _emberData['default'].Model.extend({
    name: attr('string'),
    price: attr('string'),
    shortDescription: attr('string'),
    longDescription: attr('string'),
    type: attr('string'), // 'lesson' or 'course'
    course: _emberData['default'].belongsTo('course'),
    orders: _emberData['default'].hasMany('orders', { async: true }),
    coupons: _emberData['default'].hasMany('coupons', { async: true })
  });
});