define('btw-develop/controllers/admin/courses/course', ['exports', 'ember', 'btw-develop/utils/dialogs'], function (exports, _ember, _btwDevelopUtilsDialogs) {
  var computed = _ember['default'].computed;
  var inject = _ember['default'].inject;
  exports['default'] = _ember['default'].Controller.extend({
    store: inject.service(),
    units: computed.reads('model.units'),

    refreshUnits: computed('model', function () {
      var model = this.get('model');
      var hasFullModel = this.get('model.name');
      if (!hasFullModel) {
        return this.set('model', this.store.findRecord('course', model.id));
      }

      return;
    }),

    actions: {
      saveCourse: function saveCourse() {
        this.get('model').save();
      },

      removeCourse: function removeCourse() {
        var _this = this;

        (0, _btwDevelopUtilsDialogs.windowConfirm)('This will permanently remove this Course, are you sure you want to remove it?').then(function (answer) {
          if (answer === true) {
            _this.get('model').deleteRecord();
            _this.get('model').save();
            _this.transitionToRoute('admin.courses');
          }
        });
      },

      addTest: function addTest() {
        var _this2 = this;

        (0, _btwDevelopUtilsDialogs.windowPrompt)('Test Name').then(function (answer) {
          var newTest = _this2.get('store').createRecord('quiz', {
            name: answer
          });
          _this2.get('model.tests').addObject(newTest);
          newTest.save().then(function () {
            return _this2.get('model').save();
          });
        });
      },

      addUnit: function addUnit() {
        var thisCourse = this.get('model');
        var thisCourseId = thisCourse.get('id');
        var store = this.get('store');
        (0, _btwDevelopUtilsDialogs.windowPrompt)('New Unit Name').then(function (answer) {
          var newUnit = store.createRecord('unit', {
            name: answer,
            course: thisCourseId
          });
          thisCourse.get('units').addObject(newUnit);
          newUnit.save().then(function () {
            return thisCourse.save();
          });
        });
      },

      createSection: function createSection(unit) {
        var _this3 = this;

        (0, _btwDevelopUtilsDialogs.windowPrompt)('Section Name').then(function (answer) {
          var newSection = _this3.get('store').createRecord('section', {
            name: answer
          });
          unit.get('sections').addObject(newSection);
          newSection.save().then(function () {
            return unit.save();
          });
        });
      },

      createQuiz: function createQuiz(unit) {
        var _this4 = this;

        (0, _btwDevelopUtilsDialogs.windowPrompt)('Quiz Name').then(function (answer) {
          var newQuiz = _this4.get('store').createRecord('quiz', {
            name: answer
          });
          unit.get('quizzes').addObject(newQuiz);
          newQuiz.save().then(function () {
            return unit.save();
          });
        });
      }
    }
  });
});