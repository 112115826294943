define('btw-develop/controllers/student/course/section', ['exports', 'ember'], function (exports, _ember) {
  var computed = _ember['default'].computed;
  exports['default'] = _ember['default'].Controller.extend({
    section: computed.reads('model'),
    actions: {
      nextSection: function nextSection() {
        return true;
      }
    }
  });
});