define('btw-develop/app', ['exports', 'ember', 'btw-develop/resolver', 'ember-load-initializers', 'btw-develop/config/environment', 'btw-develop/models/custom-inflector-rules'], function (exports, _ember, _btwDevelopResolver, _emberLoadInitializers, _btwDevelopConfigEnvironment, _btwDevelopModelsCustomInflectorRules) {

  var App = undefined;

  _ember['default'].MODEL_FACTORY_INJECTIONS = true;

  App = _ember['default'].Application.extend({
    modulePrefix: _btwDevelopConfigEnvironment['default'].modulePrefix,
    podModulePrefix: _btwDevelopConfigEnvironment['default'].podModulePrefix,
    Resolver: _btwDevelopResolver['default']
  });

  window.vex.defaultOptions.className = 'vex-theme-os';

  (0, _emberLoadInitializers['default'])(App, _btwDevelopConfigEnvironment['default'].modulePrefix);

  exports['default'] = App;
});