define('btw-develop/routes/application', ['exports', 'ember'], function (exports, _ember) {
  exports['default'] = _ember['default'].Route.extend({
    beforeModel: function beforeModel() {
      return this.get('session').fetch()['catch'](function () {});
    },

    model: function model() {
      var _this = this;

      var session = this.get('session');
      var isLoggedIn = session.get('isAuthenticated');
      if (!isLoggedIn) {
        this.transitionTo('login');
      } else {
        var uid = session.get('currentUser.uid');
        return this.store.query('student', { orderBy: 'uid', equalTo: uid }).then(function (student) {
          if (student.content.length) {
            _this.transitionTo('student');
          } else {
            _this.transitionTo('admin');
          }
        })['catch'](function (error) {
          return error;
        });
      }
    },
    actions: {
      invalidateSession: function invalidateSession() {
        this.get('session').close();
        // this.controller.send('toggle', 'sub-sidebar');
        this.transitionTo('login');
      }
    }
  });
});