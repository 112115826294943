define('btw-develop/controllers/student/products/product', ['exports', 'ember', 'ember-data', 'btw-develop/utils/dialogs'], function (exports, _ember, _emberData, _btwDevelopUtilsDialogs) {
  var computed = _ember['default'].computed;
  var inject = _ember['default'].inject;
  exports['default'] = _ember['default'].Controller.extend({
    userInformation: inject.service(),
    ajax: inject.service(),
    moment: inject.service(),

    student: computed.reads('userInformation.user.content.firstObject'),

    processingPayment: false,

    product: computed.reads('model'),
    productPriceInCents: computed('product', function () {
      return this.get('product.price') * 100;
    }),

    productPrice: computed('productPriceInCents', 'acceptedCoupon', function () {
      var coupon = this.get('acceptedCoupon');
      var productPriceInCents = this.get('productPriceInCents');
      if (coupon) {
        return coupon.get('discountPrice') * 100;
      } else {
        return productPriceInCents;
      }
    }),

    ordersForProduct: computed('product', function () {
      var product = this.get('product');
      return _emberData['default'].PromiseObject.create({
        promise: this.get('student.orders').then(function (orders) {
          return orders.filter(function (order) {
            if (order.get('productId')) {
              return order.get('productId') === product.get('id');
            } else {
              return order.get('productName') === product.get('name');
            }
          });
        })
      });
    }),

    token: null,

    couponCode: null,
    acceptedCoupon: null,

    useCoupon: computed.bool('acceptedCoupon'),

    orderingProduct: null,

    actions: {
      reOrderProduct: function reOrderProduct() {},

      startProduct: function startProduct(product) {
        var _this = this;

        if (product.get('type') === 'course') {
          return product.get('course').then(function (resolved) {
            _this.transitionToRoute('student.course', resolved);
          });
        }
      },

      applyCoupon: function applyCoupon() {
        var _this2 = this;

        var code = this.get('couponCode');
        this.get('product.coupons').then(function (coupons) {
          var matchedCode = coupons.filter(function (coupon) {
            return coupon.get('code') === code;
          });
          var match = matchedCode.get('firstObject');
          if (match) {
            _this2.set('acceptedCoupon', match);
          } else {
            (0, _btwDevelopUtilsDialogs.windowAlert)('that is not a valid coupon code');
          }
        });
      },

      processStripeToken: function processStripeToken(_ref) {
        var _this3 = this;

        var card = _ref.card;
        var email = _ref.email;
        var id = _ref.id;

        this.set('processingPayment', true);
        var price = this.get('productPrice');
        this.get('ajax').request('https://us-central1-btwdriving-546dd.cloudfunctions.net/purchase', {
          method: 'POST',
          data: {
            card: card,
            price: price || 50,
            email: email,
            id: id
          }
        }).then(function (stripeOrder) {
          var currentUser = _this3.get('session.currentUser.uid');
          var currentTime = Date.now();
          var product = _this3.get('product');
          var student = _this3.get('student');
          var firstName = student.get('firstName');
          var studentEmail = student.get('email');
          var studentZip = student.get('zip') || '00000';
          var studentAddress = student.get('address') + ' ' + student.get('city') + ' ' + student.get('state') + ' ' + studentZip;
          var productName = product.get('name');
          var store = _this3.get('store');
          var coupon = _this3.get('acceptedCoupon');

          var newOrder = store.createRecord('order', {
            price: price || 50,
            paidAt: currentTime,
            productName: product.get('name'),
            productId: product.get('id'),
            studentId: currentUser,
            stripeId: stripeOrder.id,
            inStore: false
          });

          if (coupon) {
            newOrder.get('coupons').addObject(coupon);
          }

          product.get('orders').addObject(newOrder);
          student.get('orders').addObject(newOrder);
          student.get('products').addObject(product);
          newOrder.save().then(function () {
            student.save().then(function () {
              product.save().then(function () {
                _this3.set('processingPayment', false);
                _this3.get('ajax').request('https://us-central1-btwdriving-546dd.cloudfunctions.net/sendPurchaseConfirmationEmail', {
                  method: 'POST',
                  data: {
                    email: studentEmail,
                    name: firstName,
                    price: price,
                    orderNumber: newOrder.get('id'),
                    stripeOrderNumber: stripeOrder.id,
                    paidAt: _this3.get('moment').moment().format('MMM Do YYYY HH:ss'),
                    productName: productName,
                    studentFullName: student.get('fullName'),
                    studentPhone: student.get('phone'),
                    studentAddress: studentAddress
                  }
                }).then(function (message) {
                  console.log(message);
                })['catch'](function (error) {
                  _this3.set('processingPayment', false);
                  console.log(error);
                });
                _this3.notifyPropertyChange('ordersForProduct');
              });
            });
          });

          (0, _btwDevelopUtilsDialogs.windowAlert)('Your payment was successful!');
        })['catch'](function () {
          _this3.set('processingPayment', false);
          (0, _btwDevelopUtilsDialogs.windowAlert)('Your payment did not go through! Please try again. If the problem persists, please contact us');
        });
      }
    }
  });
});