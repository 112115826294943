define('btw-develop/controllers/admin/index', ['exports', 'ember', 'btw-develop/utils/dialogs'], function (exports, _ember, _btwDevelopUtilsDialogs) {
  var
  // computed,
  inject = _ember['default'].inject;
  var RSVP = _ember['default'].RSVP;
  exports['default'] = _ember['default'].Controller.extend({
    firebase: inject.service(),
    firebaseUtil: inject.service(),
    moment: inject.service(),
    actions: {
      submitChanges: function submitChanges() {
        this.get('model').save();
      },

      revertChanges: function revertChanges() {
        this.get('model').rollbackAttributes();
      },

      uploadLogo: function uploadLogo(e) {
        this.send('uploadNewImage', e, 'logoImageUrl');
      },

      uploadSignInPageImage: function uploadSignInPageImage(e) {
        this.send('uploadNewImage', e, 'signInImageUrl');
      },

      uploadBackgroundImage: function uploadBackgroundImage(e) {
        this.send('uploadNewImage', e, 'backgroundImageUrl');
      },

      uploadNewImage: function uploadNewImage(event, type) {
        var _this = this;

        var file = event.target.files[0];
        if (!file) {
          return;
        }

        var model = this.get('model');
        var timestamp = this.get('moment').moment().format('MMM Do YYYY HH:ss');
        var metadata = {
          timeCreated: timestamp,
          name: type
        };

        var reader = new FileReader();
        reader.readAsArrayBuffer(file);
        reader.onload = function (e) {
          // browser completed reading file - display it
          _this.get('firebaseUtil').uploadFile(type, e.target.result, metadata, onStateChange).then(function (downloadURL) {
            // Do something with `downloadURL`
            model.set(type, downloadURL);
            model.save().then(function () {
              return (0, _btwDevelopUtilsDialogs.windowAlert)('your image has been uploaded successfully to Google Cloud Storage');
            });
          })['catch'](function (error) {
            // Do something with `error`
            return (0, _btwDevelopUtilsDialogs.windowAlert)(error);
          });
          alert(e.target.result);
        };
      }
    }
  });

  function onStateChange(snapshot) {
    var progress = snapshot.bytesTransferred / snapshot.totalBytes * 100;
  }
});
/* global moment */