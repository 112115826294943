define('btw-develop/routes/admin', ['exports', 'ember'], function (exports, _ember) {
  exports['default'] = _ember['default'].Route.extend({
    model: function model() {
      var session = this.get('session');
      var adminUid = session.get('currentUser.uid');
      return this.store.query('admin', { orderBy: 'uid', equalTo: adminUid }).then(function (admin) {
        return admin;
      })['catch'](function (error) {
        return error;
      });
    }
  });
});