define('btw-develop/controllers/application', ['exports', 'ember', 'ember-data'], function (exports, _ember, _emberData) {
  var inject = _ember['default'].inject;
  var computed = _ember['default'].computed;
  exports['default'] = _ember['default'].Controller.extend({
    leftSidebarOpen: false,
    leftSideBarLockedOpen: false,

    showSupportDialog: false,

    userInformation: inject.service(),
    userInfo: computed.reads('userInformation.user.content.firstObject'),
    setting: inject.service(),

    isLoading: computed.reads('setting.settings.isPending'),

    backgroundImage: computed('isLoading', function () {
      return _emberData['default'].PromiseObject.create({
        promise: this.get('setting.settings').then(function (settings) {
          if (settings) {
            var background = settings.get('backgroundImageUrl');
            return _ember['default'].String.htmlSafe('background-image: url(' + background + ')');
          }
        })
      });
    }),

    actions: {
      toggle: function toggle(propName) {
        this.toggleProperty(propName);
      },

      invalidateSession: function invalidateSession() {
        this.get('session').close();
        this.send('toggle', 'leftSideBarLockedOpen');
        this.transitionToRoute('login');
        this.notifyPropertyChange('userInfo');
      },

      editInformation: function editInformation() {
        return true;
      },

      closeSupportDialog: function closeSupportDialog() {
        this.set('showSupportDialog', false);
      }
    }
  });
});