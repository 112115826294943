define('btw-develop/routes/student/products', ['exports', 'ember'], function (exports, _ember) {
  exports['default'] = _ember['default'].Route.extend({
    navigator: _ember['default'].inject.service(),
    model: function model() {
      var session = this.get('session');
      var studentUid = session.get('currentUser.uid');
      return this.store.query('student', { orderBy: 'uid', equalTo: studentUid }).then(function (student) {
        return student;
      })['catch'](function (error) {
        return error;
      });
    },

    setupController: function setupController(controller, model) {
      this._super(controller, model);

      // get the whole list of products
      var products = this.get('store').findAll('product');
      controller.set('products', products);
    }
  });
});